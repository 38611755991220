const formatPriceShort = function(price: number|undefined): string {
    if (!price) {
        console.warn('Price is undefined');
        return '';
    }
    const parts = price.toFixed(2).split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1];

    if (decimalPart === '00') {
        return `${integerPart},-`;
    } else {
        return `${integerPart},${decimalPart}`;
    }
}

export default formatPriceShort;
